import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const MarketingEbook = () => (
  <Layout>
    <SEO
      title="Marketing Your First Ebook"
      description="Another benefit is that an ebook is achievable. For a first step into making digital products, it’s one of the easier options, depending on what you want to write"
    />
    <h1>Marketing Your First Ebook</h1>


    <h2>Why an Ebook?</h2>
    <p>You probably don’t need convincing on this one because I’m sure you know the advantages already but let’s go over them quickly anyway. An ebook is a digital product so all of the cost is up-front. You can either make it all yourself which costs you time or you can pay to have it made which is going to cost you money.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/kTYGj6J.jpg"
        alt="marketing your first ebook"
        description="open book with front and back on flat surface shot from table level"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@otutunaru"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Olga Tutunaru
      </a>
      {" "} on Unsplash
    </p>

    <p>This all means that when it’s done, you don’t have to factor any more cost into production. You can focus on marketing and any other things you have to get on with.</p>
    <p>For a first “book”, an ebook is a good way to go because you can focus on content and less on all the particulars that go into formatting and publishing to get accepted on a publishing platform. It’s not to say those things are bad, they are actually good because they standardise the industry and make sure everyone is meeting the minimum standards.</p>
    <p>Another benefit is that an ebook is achievable. For a first step into making digital products, it’s one of the easier options, depending on what you want to write. You could build your own SAAS but that will take months or years.</p>
    <p>The last benefit I want to mention is the scalability. I alluded to this in the idea that the costs are loaded at the front of the project. The costs aren’t much different in selling your book to 100 people when compared to 1,000,000 people. It will probably cost you more in site hosting fees along with sales fees but that’s about it.</p>

    <h2>Build in Public</h2>
    <p>When I say building in public, I mean choosing a social media platform then treating it like a diary or progress report for your work. There’s a balance between keeping it interesting with milestones & achievements and posting every little thing.</p>
    <p>When most people talk about building in public, it’s to do with personal projects. The problem with personal projects is that they often don’t make any business sense. A good project is one that has a real world application. This will attract so many more opportunities because people know that your work has purpose.</p>
    <p>What I’m saying is that, it doesn’t have to be a personal project. It can also be: work for a client. Although, you will have to discuss with them what you can share online. You also have to make sure to keep personal information private, blurring names and addresses etc. This option is great if you are not an ideas person, you can get straight into building something.</p>
    <p>The benefit of this is that you can get real world experience with working with clients if you haven’t before. You can explain the situation and have a stress free first go at building something real.</p>
    <p>The good thing about building in public is that there are so many people to keep you accountable. If you’ve built a following, people will chase you for progress if you ever slack off. If your product appeals to your audience then you will likely win lots of new clients or customers.</p>

    <h2>Get Other People Involved - Content</h2>
    <p>If you have lots of influence already and you know your stuff then coming up with all the content yourself is the natural next step. With focus, it shouldn’t take too long to complete and you probably won’t struggle for ideas. In the end the results should be great. There is, however, another way. As you can imagine from the subhead, that is to get other people involved.</p>
    <p>I like this idea because, for example, if you get 100 people involved and give them a page each then that’s 100 pages of content. Granted, this won’t work for all book types but my example was a book of advice. 100 different people giving their number 1 tip for a solution my audience was looking for the answer to.</p>
    <p>That brings me to the second part of this section.</p>

    <h2>Get Other People Involved - Affiliate, Ownership</h2>
    <p>When it comes to marketing a product, you can be a solo salesperson and try to do it yourself. That works if you have the right service at the right price point, usually high. Another way is to employ a sales team. They sit in your office and do outreach all day in the hope of getting sales. You could also buy ads online. All viable options depending on your budget.</p>
    <p>The method I want to talk about here is affiliate marketing. An affiliate is someone who promotes a product they don’t own for a commission. The commission can be any percentage of the sale, it can also be recurring so the affiliate sees a percent of the profit every month, this also works well with recurring cost products obviously. There are two general ways the sale can be attributed back to the affiliate, a personalised discount code or through link attribution.</p>
    <p>The downside of being an affiliate is that the product owner can get shady with where the sale came from, i.e. giving no attribution and taking the full profit for themselves. Even without being shady, affiliates do a lot of marketing work. Some people are wise to affiliates and if they don’t use the discount code or click through the link but still buy, guess who still gets the profit? The product owner.</p>

    <h2>Tools</h2>
    <p>There are a few tools you can use to get started in the creation and marketing of your product. Everyone will have their own favourite for whatever reason and I’m sure all of them will help achieve what you are aiming for with enough effort but I’d like to take you through the tools I use.They haven’t let me down yet and more than that, I actually like using them.</p>
    <p>I mentioned before how publishing an ebook has varying difficulties. Well, publishing on Gumroad has to be one of the easiest. You sign up and make an account, then you can upload any digital media you like.</p>
    <p>This way you can save your book as a pdf and put it on your account so anyone can download it and read it on their phone or computer. Pretty much all phones come with a pdf reader these days so you don’t have to worry about someone needing a kindle or any other reading device that takes a particular file type.</p>
    <p>As with most services, the company needs to charge to keep the product running so they can’t do it for free. Gumroad is no different but they take their fees out of the profit you make as a percentage that goes down as your income rises. This means that they only earn if you earn. It’s great because the barrier of entry is really low and you can literally get a product up and ready to be sold in minutes.</p>

    <h2>Canva</h2>
    <p>I really like Canva. The free tier is where I always design and set out the template for ideas that I have. I start by browsing Google, Amazon and any other site that comes to mind for book covers and ideas. Then I get on Canva and look for a template that fits my vision the closest. From there I make edits to get as close to what I like as possible. I can go back and forth between finding inspiration and adjusting the design until I’m happy. After this it’s time to start replacing the placeholder text I’ve used with actual copy and images.</p>
    <p>There is a pro version of Canva that is paid and while you can get away with using the free version, if you end up using Canva a lot then you will see a lot of value in Pro. The downsides to the free version are that most of the images and graphics are restricted so you won’t be able to use them. Then there’s the templates, if you find a template you really like, chances are that you can only use it if you have paid.</p>
    <p>When it comes to exporting your finished work, you are restricted to a few options like small, lower res image formats.</p>
    <p>All of this isn’t at all a really bad thing because there are a few workarounds like re-creating the template you like from scratch with free graphics. Even if you want to use Canva as a place to get a quick mockup of a design then take it away into your favourite design software like something from Adobe, then you can do that.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default MarketingEbook
